import React from "react";
import { Recipe } from "../types";
import { graphql, Link } from "gatsby";
import Main from "../components/Main";
import RecipeCard from "../components/RecipeCard";
import styled from "styled-components";

type Props = {
  data: {
    allMdx: {
      edges: Array<Recipe>;
    };
  };
};
const Recipes: React.FC<Props> = ({ data }) => {
  console.log(data);
  return (
    <Main title="All Recipes">
      <Cards>
        {data.allMdx.edges.map(({ node }) => (
          <RecipeCard
            key={node.id}
            image={node.frontmatter?.featuredImage?.childImageSharp.fixed.src}
            href={`/recipe/${node.frontmatter.slug}`}
            timestamp={node.frontmatter.date}
            title={node.frontmatter.title}
          />
        ))}
      </Cards>
    </Main>
  );
};

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const query = graphql`
  query AllRecipesQuery {
    allMdx(sort: { order: DESC, fields: frontmatter___date }, limit: 1000) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            slug
            featuredImage {
              childImageSharp {
                fixed(width: 360, height: 360) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Recipes;
