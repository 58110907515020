import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import DateString from "./DateString";
import styled from "styled-components";

export type Props = {
  image: string;
  title: string;
  timestamp: string;
  href: string;
};

const RecipeCard: React.FC<Props> = ({ image, title, timestamp, href }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/recipe-default.jpg" }) {
        publicURL
      }
    }
  `);

  console.log(data);
  return (
    <Container to={href}>
      {image ? (
        <Img src={image} />
      ) : (
        <StaticImage
          src="../images/recipe-default.jpg"
          alt="Featured Image"
          width={360}
          height={360}
          style={{ filter: "blur(20)" }}
        />
      )}
      <h4>{title}</h4>
      <h5>
        <DateString date={timestamp} />
      </h5>
    </Container>
  );
};

export default RecipeCard;

const Img = styled.img``;

const Container = styled(Link)`
  flex: 0 0 360px;
  margin: 16px;
  max-width: calc(100% - 16px - 16px);
  background: ${(p) => p.theme.lightShades};
  transition: 400ms background ease-out;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  overflow: hidden;

  ${Img} {
    filter: brightness(0.8) sepia(0.2);
    transition: filter 400ms ease-out;
  }

  &:hover {
    background: ${(p) => p.theme.lightAccent};

    ${Img} {
      filter: brightness(1) sepia(0);
    }
  }

  h4,
  h5 {
    margin: 0;
    padding-left: 8px;
    padding-bottom: 8px;
  }
`;
